import GoogleTagLoader from "@/scripts/GoogleTagLoader";
import ToltLoader from "@/scripts/ToltLoader";
import PosthogLoader from "@/scripts/PosthogLoader";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Header from "@/components/shared/Header";
import { ModalProvider } from "@/lib/contexts/ModalContext";
import ModalManager from "@/components/Modals/ModalManager";
import { initializeUtm } from "@/lib/utm";
import { useEffect } from "react";
import Head from "next/head";
import { Inter } from "next/font/google";

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
  weight: ["300", "400", "500", "600", "700"],
  variable: "--font-inter",
});

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    initializeUtm();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover, minimal-ui"
        />
      </Head>
      <div className={`${inter.variable} min-h-[100dvh] font-sans`}>
        <ModalProvider>
          <ToltLoader />
          <GoogleTagLoader />
          <PosthogLoader />
          <Header />
          <Component {...pageProps} />
          <ModalManager />
        </ModalProvider>
      </div>
    </>
  );
}
