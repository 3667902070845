import { Button } from "../ui/button";
import FancyButton from "../ui/fancy-button";
import Image from "next/image";
import Link from "next/link";
import { ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { cn } from "@/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { appendUtmParams } from "@/lib/utm";

type MenuItem = {
  title: string;
  href: string;
  description?: string;
};

const menuItems = {
  features: {
    generateVideos: [
      {
        title: "Story Video",
        href: "https://crayo.ai/create/story-video?step=1",
        description: "Create reddit-style story videos in seconds with our AI script generator",
      },
      {
        title: "Split-Screen Video",
        href: "https://crayo.ai/tools/voiceovers?step=0",
        description:
          "Format a split-screen video with our optimized subtitles and library of gameplay",
      },
      {
        title: "Fake Texts Video",
        href: "https://crayo.ai/create/text-video?step=0",
        description: "Create fake text conversation videos for Instagram, TikTok, and Shorts",
      },
    ],
    tools: [
      { title: "AI Voiceovers", href: "https://crayo.ai/tools/voiceovers" },
      { title: "AI Images", href: "https://crayo.ai/tools/images" },
      { title: "AI Ideas", href: "https://crayo.ai/tools/ideas" },
      { title: "YouTube Downloader", href: "https://crayo.ai/tools/youtube-downloader" },
      { title: "TikTok Downloader", href: "https://crayo.ai/tools/tiktok-downloader" },
    ],
  },
  useCases: [
    {
      title: "TikTok Virality",
      href: "https://crayo.ai/dashboard",
      description: "Use Crayo's AI tools to create viral TikTok content.",
    },
    {
      title: "YouTube Automation",
      href: "https://crayo.ai/dashboard",
      description: "Automate YouTube content creation to save time and boost productivity.",
    },
    {
      title: "Faceless Content",
      href: "https://crayo.ai/dashboard",
      description: "Create engaging faceless videos with AI voiceovers.",
    },
    {
      title: "Instagram Monetization",
      href: "https://crayo.ai/dashboard",
      description: "Boost Instagram earnings with eye-catching Reels and Stories.",
    },
    {
      title: "Subtitles",
      href: "https://crayo.ai/dashboard",
      description: "Add accurate subtitles quickly using AI-powered generation.",
    },
  ],
  resources: [
    {
      title: "Affiliate Program",
      href: "https://crayo.tolt.io/login",
      description: "Earn 20% on all paid referrals to Crayo.",
    },
    {
      title: "Blog",
      href: "https://crayo.ai/blog",
      description: "Find news, insights, and best practices.",
    },
    {
      title: "Community Discord",
      href: "https://crayo.ai/discord",
      description: "Join for customer support and feedback.",
    },
  ],
};

const MobileStickyHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const preventScroll = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    if (isOpen) {
      document.addEventListener("touchmove", preventScroll, { passive: false });
      document.addEventListener("wheel", preventScroll, { passive: false });
    }
    return () => {
      document.removeEventListener("touchmove", preventScroll);
      document.removeEventListener("wheel", preventScroll);
    };
  }, [isOpen]);

  return (
    <>
      <div className="fixed top-0 z-40 h-16 w-full bg-white bg-opacity-20 backdrop-blur-lg md:hidden" />

      {/* accordion trigger hack:
          - this div is positioned + sized to perfectly cover the accordion _except_ for the menu icon
            - this enables full width accordion content w/o full width pressability */}
      <div className="fixed -left-1 top-[1vh] z-50 ml-[5vw] flex w-full max-w-[80%] items-center justify-between rounded-2xl border-none bg-transparent py-3 pl-4 pr-4 md:hidden">
        <div className="w-1/4">
          <Link
            href="/"
            className="w-1/4 max-w-[80px]"
            onClick={(e) => {
              if (window.location.pathname === "/") {
                e.preventDefault();
              }
            }}
          >
            <Image
              src="https://cdn-crayo.com/lp/public/crayoBlue.png"
              alt="CRAYO Logo"
              width={120}
              height={40}
            />
          </Link>
        </div>
        <Button
          href="https://crayo.ai/dashboard"
          className="w-fit rounded-xl bg-[#3870FF] px-4 py-2.5 text-sm font-bold hover:bg-[#3870FF]/80"
        >
          Sign up
        </Button>
      </div>

      <div className="contents @container">
        <Accordion
          type="single"
          collapsible
          orientation="horizontal"
          value={isOpen ? "mobile-menu" : ""}
          onValueChange={toggleMenu}
          className="fixed top-[1vh] z-40 mx-[4vw] flex w-full max-w-[92%] items-center justify-between self-center rounded-xl border border-gray-200 bg-white pb-1 pr-4 pt-0.5 md:hidden"
        >
          <AccordionItem value="mobile-menu" className="border-none">
            <AccordionTrigger value="mobile-menu" className="flex w-full justify-end">
              <div className="size-4 p-0 transition-transform duration-200 hover:scale-110 ">
                <Image
                  src="https://cdn-crayo.com/lp/public/hamburger.png"
                  alt="Menu"
                  width={16}
                  height={16}
                  className="group-data-[state=open]:hidden"
                />
                <X className="size-6 -translate-x-1 -translate-y-[3px] group-data-[state=closed]:hidden" />
              </div>
              {/* bottom + side margin blurs (shown when accordion is open) */}
              <div className="absolute -bottom-[209px] -left-10 -z-10 h-52 w-[120%] bg-white bg-opacity-20 backdrop-blur-sm group-data-[state=open]:block group-data-[state=closed]:hidden" />
              <div className="absolute -left-[41px] top-0 -z-10 h-[81vh] w-[39.5px] bg-white bg-opacity-20 backdrop-blur-sm group-data-[state=open]:block group-data-[state=closed]:hidden" />
              <div className="absolute -right-[41px] top-0 -z-10 h-[81vh] w-[39.5px] bg-white bg-opacity-20 backdrop-blur-sm group-data-[state=open]:block group-data-[state=closed]:hidden" />
            </AccordionTrigger>
            <AccordionContent className="flex h-full flex-col group-data-[state=open]:overflow-hidden">
              {/* mobile menu */}
              <div className="flex h-[72vh] flex-col pl-4 pr-0 pt-8">
                <Accordion type="single" collapsible>
                  <AccordionItem value="features">
                    <AccordionTrigger className="align-start flex flex-1 items-center justify-between gap-2 py-2 pb-2 pt-3 text-base font-medium transition-all [&[data-state=open]>svg]:rotate-180">
                      Features
                      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="space-y-3">
                        <div>
                          <p className="mb-2 border-b pb-1 text-[10px]">GENERATE VIDEOS</p>
                          {menuItems.features.generateVideos.map((item) => (
                            <NavLink key={item.title} {...item} onClick={toggleMenu} />
                          ))}
                        </div>
                        <div>
                          <p className="mb-2 border-b pb-1 text-[10px]">CRAYO TOOLS</p>
                          {menuItems.features.tools.map((item) => (
                            <NavLink key={item.title} {...item} onClick={toggleMenu} />
                          ))}
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="use-cases">
                    <AccordionTrigger className="align-start flex flex-1 items-center justify-between gap-2 py-2 pb-2 pt-3 text-base font-medium transition-all [&[data-state=open]>svg]:rotate-180">
                      Use Cases
                      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="grid gap-1">
                        {menuItems.useCases.map((item) => (
                          <NavLink key={item.title} {...item} onClick={toggleMenu} />
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem value="resources">
                    <AccordionTrigger className="align-start flex flex-1 items-center justify-between gap-2 py-2 pb-2 pt-3 text-base font-medium transition-all [&[data-state=open]>svg]:rotate-180">
                      Resources
                      <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="grid gap-1">
                        {menuItems.resources.map((item) => (
                          <NavLink key={item.title} {...item} onClick={toggleMenu} />
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                <Link
                  prefetch={false}
                  href="https://crayo.ai/discord"
                  className="align-center block items-center justify-center border-b pb-3 pt-4 text-start text-base font-medium tracking-tight"
                  onClick={toggleMenu}
                >
                  Discord
                </Link>
                <Link
                  href="/pricing"
                  className="align-center block items-center justify-center border-b pb-3 pt-4 text-start text-base font-medium tracking-tight"
                  onClick={toggleMenu}
                >
                  Pricing
                </Link>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

type NavLinkProps = MenuItem & {
  onClick?: () => void;
};

const NavLink = ({ href, title, description, onClick }: NavLinkProps) => {
  return (
    <Link
      href={appendUtmParams(href)}
      prefetch={false}
      onClick={onClick}
      className="group block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="space-y-1">
          <div className="flex flex-row items-center gap-1 text-sm font-medium leading-none">
            {title}
            <Image
              src="https://cdn-crayo.com/lp/public/rightArrow.png"
              alt="arrow"
              width={10}
              height={10}
              className="-translate-x-4 transform opacity-0 transition-all duration-300 ease-in-out group-hover:translate-x-0 group-hover:opacity-100"
              draggable={false}
            />
          </div>
          {description && (
            <p className="line-clamp-2 text-xs leading-snug text-muted-foreground">{description}</p>
          )}
        </div>
      </div>
    </Link>
  );
};

const DesktopMenuContent = () => (
  <NavigationMenu className="h-full w-3/5 lg:w-1/2">
    <NavigationMenuList className="space-x-5">
      <NavigationMenuItem>
        <NavigationMenuTrigger className="text-base lg:text-lg">Features</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul className="relative grid md:w-[550px] lg:w-[650px] lg:grid-cols-[1.25fr_.75fr]">
            <li className="row-span-3 border-r border-slate-200 p-4">
              <p className="pb-1 text-[10px]">GENERATE VIDEOS</p>
              <div>
                {menuItems.features.generateVideos.map((item) => (
                  <NavLink key={item.title} {...item} />
                ))}
              </div>
            </li>
            <li className="relative">
              <div className="relative z-10 p-4">
                <p className="pb-1 text-[10px]">CRAYO TOOLS</p>
                {menuItems.features.tools.map((item) => (
                  <NavLink key={item.title} {...item} />
                ))}
              </div>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem>
        <NavigationMenuTrigger className="text-base lg:text-lg">Use Cases</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
            {menuItems.useCases.map((item) => (
              <NavLink key={item.title} {...item} />
            ))}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem>
        <NavigationMenuTrigger className="text-base lg:text-lg">Resources</NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
            {menuItems.resources.map((item) => (
              <NavLink key={item.title} {...item} />
            ))}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
      <NavigationMenuItem>
        <Link href="/pricing" legacyBehavior passHref>
          <NavigationMenuLink className={navigationMenuTriggerStyle()}>Pricing</NavigationMenuLink>
        </Link>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
);

const Header = ({ className }: { className?: string }) => (
  <>
    <MobileStickyHeader />

    {/* desktop */}
    <header
      id="top"
      className={cn(
        "mx-auto hidden w-full max-w-[95%] items-center justify-between self-center overflow-visible p-6 md:flex xl:max-w-screen-2xl",
        className,
      )}
    >
      <Link
        href="/"
        className="w-1/4 max-w-[80px] md:max-w-[120px] lg:w-1/4"
        onClick={(e) => {
          if (window.location.pathname === "/") {
            e.preventDefault();
          }
        }}
      >
        <Image
          src="https://cdn-crayo.com/lp/public/crayoBlue.png"
          alt="CRAYO Logo"
          width={120}
          height={40}
        />
      </Link>
      <div className="flex space-x-6">
        <Button
          href="https://crayo.ai/dashboard"
          className="w-full space-x-2 rounded-lg bg-[#3870FF] px-3 py-1.5 hover:bg-[#3870FF]/80 md:hidden"
        >
          <span className="text-sm text-white">Sign up</span>
        </Button>
      </div>
      <div className="hidden w-full items-center justify-center md:flex md:w-fit">
        <DesktopMenuContent />
      </div>
      <div className="hidden w-1/5 items-center justify-end md:flex xl:w-1/4">
        <FancyButton
          className="space-x-1.5 px-5 py-3.5 text-[15px] font-medium leading-[20px] -tracking-[0.01em]"
          href="https://crayo.ai/dashboard"
        >
          <Image
            src="https://cdn-crayo.com/lp/public/lightningWhite.png"
            alt="Lightning"
            width={12}
            height={14}
          />
          <span className="text-base text-white">
            Try <span className="hidden lg:inline">Crayo</span> Now
          </span>
        </FancyButton>
      </div>
    </header>
  </>
);

export default Header;
