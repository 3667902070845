import React from "react";
import { cn } from "@/lib/utils";
import Link from "next/link";

interface FancyButtonProps {
  onClick?: () => void;
  href?: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
}

const FancyButton: React.FC<FancyButtonProps> = ({
  onClick,
  href,
  children,
  disabled = false,
  className,
  size = "md",
}) => {
  const sizeClasses = {
    sm: "px-2.5 py-1.5 text-xs",
    md: "px-3.5 py-2 text-sm",
    lg: "px-4 py-2.5 text-base",
    xl: "px-5 py-3 text-lg",
  };

  const sharedClassName = cn(
    "border-linear-12 flex flex-row items-center justify-center overflow-hidden rounded-full border-[1px] border-solid font-semibold leading-[normal] tracking-[normal] text-white shadow-[0px_1px_2px_rgba(14,_18,_27,_0.24),_0px_0px_0px_1px_#5B7CFF] duration-200 [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.37),_rgba(255,_255,_255,_0)),_#3860FF]",
    sizeClasses[size],
    disabled ? "cursor-not-allowed opacity-50 hover:opacity-50" : "cursor-pointer hover:opacity-90",
    className,
  );

  if (href) {
    return (
      <Link href={href} className={sharedClassName}>
        {children}
      </Link>
    );
  }

  return (
    <button className={sharedClassName} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default FancyButton;
